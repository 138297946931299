/**
 * Checks whether `arg` is a promise or not.
 *
 * @returns {boolean}
 */
function isPromise(arg) {
  return Boolean(arg && "then" in arg && arg.then);
}

export { isPromise };
